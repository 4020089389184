import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { OPERATION_TYPE } from 'enums/payments'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const PaymentDialog = ({ payment, onSubmit }) => {
    const currencies = [
        {
            id: 'USD',
            name: 'Доллар'
        },
        {
            id: 'KZT',
            name: 'Тенге'
        },
    ]

    const paymentMethods = [
        {
            id: 'cash',
            name: 'Наличный'
        },
        {
            id: 'kaspi',
            name: 'Каспи'
        },
        {
            id: 'bcc',
            name: 'БЦК'
        },
    ]

    const operationTypes = [
        {
            id: OPERATION_TYPE.pay,
            name: 'Оплата'
        },
        {
            id: OPERATION_TYPE.refund,
            name: 'Возврат'
        },
    ]

    const { register,
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
        watch,
        unregister
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            amount: 100,
            operationType: _.get(payment, 'operationType'),
            currency: 'USD',
            paymentMethod: 'cash',
            exchangeRate: localStorage.getItem('currancyRate[USD]') ? Number(localStorage.getItem('currancyRate[USD]')) + 5 : "",
            amountInUSD: ''
        },
    });

    const currency = watch('currency')
    const amount = watch('amount')

    useEffect(() => {
        console.log(`currency: `, currency);

        if (currency === "USD") {
            // register('exchangeRate')
            // unregister('exchangeRate')
        } else {
            // For other currencies            

            let usd = Number(getValues('exchangeRate'))
            const amountInUSD = Number(getValues('amount') / usd)
            console.log(`amountInUSD:`, amountInUSD);
            setValue('amountInUSD', Number(amountInUSD).toFixed(2))
        }

    }, [currency, getValues, register, setValue, unregister])


    useEffect(() => {
        if (currency === 'USD') {
            setValue('amountInUSD', amount)
        } else {
            // For other currencies
            let usd = Number(getValues('exchangeRate'))
            const amountInUSD = Number(getValues('amount') / usd)
            setValue('amountInUSD', amountInUSD, {
                shouldTouch: true,
                shouldValidate: true
            })
        }
    }, [amount, currency, getValues, setValue])

    return (
        <div className='p-32'>
            <h3 className='m-b-32'>
                {payment?.operationType === OPERATION_TYPE.pay ? 'Добавить платеж' : payment?.operationType === OPERATION_TYPE.refund ? 'Возврат денежных средств' : ''}
            </h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='m-b-16 w-100'>
                    <TextField fullWidth {...register('amount', { required: true, minLength: 1, min: 1 })} type='number' label='Сумма'></TextField>
                </div>
                <div className='m-b-16'>
                    <Controller control={control} name="operationType" rules={{ required: true }} render={({ field }) => {
                        return <FormControl fullWidth disabled>
                            <InputLabel>Тип операций</InputLabel>
                            <Select {...field} label="ип операций">
                                {operationTypes?.map((c, index) => {
                                    return (
                                        <MenuItem key={index} value={c?.id}>
                                            {c?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }}>
                    </Controller>
                </div>
                <div className='m-b-16'>
                    <Controller control={control} name="currency" rules={{ required: true }} render={({ field }) => {
                        return <FormControl fullWidth>
                            <InputLabel>Валюта</InputLabel>
                            <Select {...field} value={field.value} label="Валюта">
                                {currencies?.map((c, index) => {
                                    return (
                                        <MenuItem key={index} value={c?.id}>
                                            {c?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }}>

                    </Controller>
                </div>
                <div className='m-b-16'>
                    <Controller control={control} name="paymentMethod" rules={{ required: true }} render={({ field }) => {
                        return <FormControl fullWidth>
                            <InputLabel>Способ оплаты</InputLabel>
                            <Select {...field} label="Способ оплаты">
                                {paymentMethods?.map((c, index) => {
                                    return (
                                        <MenuItem key={index} value={c?.id}>
                                            {c?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    }}>
                    </Controller>
                </div>
                {currency === "USD" ? null : <>
                    <div className='m-b-16 w-100'>
                        <TextField fullWidth {...register('exchangeRate', { required: true })} disabled label='Курс доллара'></TextField>
                    </div>
                    <div className='m-b-16 w-100'>
                        <TextField fullWidth {...register('amountInUSD', { required: true })} disabled label='Сумма в долларах'></TextField>
                    </div>
                </>}
                <div className='m-b-32'>
                    <TextField fullWidth {...register('comment')} placeholder='Комментария к оплате'></TextField>
                </div>
                <div>
                    <Button fullWidth variant='contained' type='submit'>
                        {payment?.operationType === OPERATION_TYPE.pay ? 'Добавить платеж' : payment?.operationType === OPERATION_TYPE.refund ? 'Возврат денежных средств' : ''}
                    </Button>
                </div>
                {/* <pre className="m-t-32">
                    {JSON.stringify(watch(), null, 4)}
                </pre> */}
            </form>
        </div>
    )
}
