export enum genderEnum {
  M = "Мужчина",
  F = "Женщина",
}

export const genders = [
  {
    id: 'F',
    name: 'Женщина'
  },
  {
    id: 'M',
    name: 'Мужчина'
  }
]