import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link } from 'react-router-dom';
import _ from 'lodash'
import flightsService from 'services/admin/flightsService';
import { MdFlightTakeoff } from 'react-icons/md';
import './flight-card.scss'
import { snack } from 'components/Snack/Snack';
import { weeks } from 'pages/Admin/Flights/FlightForm';
import { durationToHours, toDate, toFullDateName } from 'helpers/dateHelper';

function FlightDirectionPreview({ flight, label, direction }) {
    if (!flight) {
        return null
    }

    const airport = direction === 'to' ? flight.departureAirport : flight.arrivalAirport
    const date = direction === 'to' ? flight.TourToFlight?.departureDate : flight.TourToFlight?.arrivalDate
    const time = direction === 'to' ? flight.departureTime : flight.arrivalTime

    return <div>
        <div className='m-b-8'>{label}:</div>
        <div className='m-b-8'>
            <span className='fs-24 fw-500'>{_.get(airport, 'airportCode')}</span>
        </div>
        <div className='m-b-8'>
            <span>{_.get(airport, 'city.name')}</span>
        </div>
        <div className='m-b-8'>
            <span>{date && toFullDateName(new Date(date))} {time}</span>
        </div>
    </div>
}

export default function FlightCard({ flight, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить рейс?')

            if (isConfirmed) {
                await flightsService.delete(item.id);
                snack('Рейс успешно удален!')
                loadData && loadData()

            }
        } catch (ex) {
            console.error('При удаление рейса вышла ошибка:', ex?.message)
            snack(ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8 flex-between flight-info flexr'>
                        <FlightDirectionPreview flight={flight} label={'От'} direction="to"></FlightDirectionPreview>
                        {/* To */}
                        <div className='flex flex-col flex-center text-center'>
                            <MdFlightTakeoff size={40} className="boardpass-icon"></MdFlightTakeoff>
                            <div className='fs-16'>{`${flight.durationHours} ч ${flight.durationMinutes} мин`}</div>
                        </div>
                        <FlightDirectionPreview flight={flight} label={`До`} direction="return"></FlightDirectionPreview>

                        <div className='m-b-8'>
                            <div className='m-b-8'>
                                <img className='airline-icon' src={flight.airline.photo} alt='' />
                            </div>
                            <div>Flight No: {flight.flightNumber}</div>
                        </div>
                    </div>
                    {/* <div className='m-t-16 w-100 fs-12 gap-8 text-right'>
                        {flight.scheduleDays ?
                            Object.entries(flight.scheduleDays)
                                .filter(([k, v]) => v === true)
                                .map(v => ({ id: v[0], name: v[1] }))
                                .map(d => {
                                    return <span className='m-l-8'>
                                        {weeks.find(dd => dd.id === d.id).name}
                                    </span>
                                }) : null}</div> */}
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`edit/${flight.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(flight)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
