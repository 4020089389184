import { Card, CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { useEffect, useState } from "react";
import { MdAirplaneTicket } from "react-icons/md";
import GroupTripService from "services/admin/GroupTripService";

const GroupTripsCount = ({ month }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await GroupTripService.count({ month })
      setData(result.data)
    } catch (ex) {
      console.error(`CustomersCount ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }} className="stat-card">
      <div className="text-center p-t-16 p-b-16">
        <MdAirplaneTicket size="42" style={{ color: 'rgba(83, 155, 255)' }}></MdAirplaneTicket>
        <h4 className="text-info">Количество поездок</h4>
        <h4 className="text-info mt-1">{data || 0}</h4>
      </div>
    </Card>
  );
};

export default GroupTripsCount