export const PAYMENT_METHOD = {
    'cash': 'Наличные',
    'kaspi': 'Каспи Банк',
    'bcc': 'Банк Центр Кредит'
}

export enum PAYMENT_STATUS {
    PENDING = "В ожидании",
    COMPLETED = "Завершен",
    ERROR = "Ошибка"
}

export enum OPERATION_TYPE {
    REFUND = "refund",
    PAY = "pay"
}