import { CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { PAYMENT_METHOD } from "data/paymentConts";
import React, { useEffect, useState } from "react";
import customersService from "services/admin/customersService";
import paymentsService from "services/admin/paymentsService";

const CustomerCityzenship = ({ month }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)


  useEffect(() => {
    if (data) {
      const ctx = document.getElementById("customerCityzenship");

      new window.Chart(ctx, {
        type: "pie",
        data: {
          labels: data.map(t => `${t.citizenshipID}: ${t.total}`),
          datasets: [
            {
              label: "Количество клиентов",
              data: data.map(t => t.total),
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);


  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await customersService.getCustomersCitizenship({ month })
      const stats = result.data.filter(d => d?.citizenshipID)
      setData(stats)
    } catch (ex) {
      console.error(`PaymentMethodStats ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div style={{ flex: 1 }} className="flex flex-column flex-center">
      <h4 className="text-center">Распределение клиентов по странам</h4>
      <p className="color-gray fs-12 m-t-8">Это полезная статистика для туристической компании, которая позволяет выяснить, из каких стран приходит большинство клиентов, чтобы сосредоточить усилия на развитии этих направлений.</p>
      <div style={{ width: 300, textAlign: "center" }}>
        <canvas id="customerCityzenship"></canvas>
      </div>
    </div>
  );
};

export default CustomerCityzenship