import { useRemoteResource } from "hooks/useRemoteResource";
import { useEffect, useRef, useState } from "react";
import { urlReplaceState } from "helpers/url";
import withRouter from "hoc/withRouter";
import { Link } from "react-router-dom";
import { Button, CircularProgress, IconButton, Pagination, TextField } from "@mui/material";
import { FaFileAlt, FaSearch } from "react-icons/fa";
import customersService from "services/admin/customersService";
import AppTable from "components/AppTable/AppTable";
import _ from "lodash";
import { MdOutlineArticle } from "react-icons/md";
import { toDate, toDocumentFormat } from "helpers/dateHelper";

function BulkButton() {
  return (
    <span>
      <Link to="../add-bulk">
        <Button variant="outlined" size="small">
          <FaFileAlt /> &nbsp; Добавить через CSV
        </Button>
      </Link>
    </span>
  );
}

const customerColumns = [
  {
    key: 'name',
    label: 'ФИО',
    render: (c) => {
      const buttons = []
      const viewButton = <Link to={`/admin/customers/view/${c.id}`}>
        {c.name || 'Отсутсвует'}
      </Link>
      buttons.push(viewButton)

      return <div className="color-blue flex gap-8">{buttons.map(ab => ab)}</div>
    }
  },
  {
    key: 'gender',
    label: 'Пол'
  },
  {
    key: 'birthdate',
    label: 'Дата рождения',
    formatter: (v) => v && toDocumentFormat(v)
  },
  {
    key: 'IDCard.iin',
    label: 'ИИН'
  },
  {
    key: 'passport.number',
    label: '№Паспорта'
  },
  {
    key: 'passport.dateOfIssue',
    label: 'Дата выдачи паспорта',
    formatter: (v) => v && toDocumentFormat(v)
  },
  {
    key: 'passport.dateOfExpire',
    label: 'Срок истечения паспорта',
    formatter: (v) => v && toDocumentFormat(v)
  },
  {
    key: 'citizenship',
    label: 'Гражданство',
    render: (c) => _.get(c, 'citizenship.name')
  },
  {
    key: 'phone',
    label: 'Номер телефона',
    render: (c) => <span className="color-blue">{_.get(c, 'user.phone')}</span>
  },
  {
    key: 'createdAt',
    label: 'Дата добавления',
    formatter: (v) => toDate(v)
  },
  {
    key: 'view',
    label: '',
    render: (b) => {
      const buttons = []
      const viewButton = <Link to={`/admin/customers/view/${b.id}`}>
        <IconButton size="small">
          <MdOutlineArticle></MdOutlineArticle>
        </IconButton>
      </Link>
      buttons.push(viewButton)

      return <div className="flex gap-8">{buttons.map(ab => ab)}</div>
    }
  },
]

const CustomerList = ({ router: { queryParams } }) => {
  const searchRef = useRef();
  const [view, setView] = useState('table')

  const [query, setQuery] = useState({
    page: queryParams.get("page") || 1,
    showFullInfo: true,
    query: queryParams.get("query") || "",
  });

  const [data, loadData, loading] = useRemoteResource(customersService.getAll, query);

  useEffect(() => {
    // Используется для обновления состояние url
    urlReplaceState(null, query);
  }, [query]);

  const onSubmit = (e) => {
    e.preventDefault();

    const query = String(searchRef.current.value).trim();
    updateSearch({ query, page: 1 });
  };

  const updateSearch = (params) => {
    setQuery({
      ...query,
      ...params,
    });
  };

  const onPageChanged = (e, _page) => {
    console.log(`onPageChanged: `, _page);
    updateSearch({ page: _page })
  }

  const buildContent = () => {
    if (loading) {
      return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    if (data?.rows && data?.rows?.length > 0) {
      if (view === "table") {
        const pageCount = Math.ceil(data?.count / 50)
        const isPaginationVisible = pageCount > 1
        return <div>
          <AppTable columnConfig={customerColumns} data={data.rows}></AppTable>
          {isPaginationVisible ? <Pagination className="m-t-32" page={query.page} count={pageCount} onChange={onPageChanged} /> : null}
        </div>
      }
    }

    return <div>Клиенты отсутсвуют. Пожалуйста, добавьте нового клиента через кнопку добавить сверху</div>
  }

  return <div>
    <div className="flex-row flex-space-between" style={{ flexWrap: "wrap" }}>
      <h2 className="text-2xl m-b-16">Cписок клиентов</h2>
      <div className="gap-4 flex" style={{ alignItems: "center" }}>
        <Link to={'../add'}>
          <Button variant="outlined" size="small">Добавить</Button>
        </Link>
        {BulkButton()}
        <Link to={'../import-from-google-sheet'}>
          <Button variant="outlined" size="small">Загрузить через Google Sheet</Button>
        </Link>
      </div>
    </div>
    <form className="search__inputs mt-4 mb-4" onSubmit={onSubmit}>
      <div className="flex">
        <TextField
          type="search"
          placeholder="Поиск по имени"
          inputRef={searchRef}
          defaultValue={query.query}
          autoComplete="off"
          className="m-w-100"
          style={{ minWidth: 300 }}
          onChange={(e) => {
            if (e.target.value === "") {
              updateSearch({
                query: '',
                page: 1
              })
            }
          }}
        ></TextField>
        <IconButton type="submit" size="large">
          <FaSearch></FaSearch>
        </IconButton>
      </div>
    </form>
    <div className="m-t-32">
      {buildContent()}
    </div>
  </div>
};

export default withRouter(CustomerList);
