import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress, IconButton, Pagination } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import GroupTripService from "services/admin/GroupTripService";
import AppTable from "components/AppTable/AppTable";
import { toFullDateName } from "helpers/dateHelper";
import { TripStatus } from "components/TripStatus/TripStatus";
import { useEffect, useState } from "react";
import { urlReplaceState } from "helpers/url";
import { LIMIT } from "data/consts";
import { Link } from "react-router-dom";
import { MdArticle, MdEdit } from "react-icons/md";


const columnConfig = [
    {
        key: 'index',
        label: '#',
        render: (t, inx) => inx + 1
    },
    {
        key: 'tourPackage.name',
        label: 'Турпакет'
    },
    {
        key: 'tourPackage.startDate',
        label: 'Дата начало поездки',
        render: (t) => toFullDateName(t.tourPackage.startDate)
    },
    {
        key: 'tourPackage.endDate',
        label: 'Дата конца поездки',
        render: (t) => toFullDateName(t.tourPackage.endDate)
    },
    {
        key: 'status',
        label: 'Статус',
        render: (t) => <TripStatus status={t.status}></TripStatus>
    },
    {
        key: 'edit',
        label: '',
        render: (b) => {
            return <div className="flex gap-8">
                <Link to={`/admin/group-trips/edit/${b.id}`}>
                    <IconButton size="small">
                        <MdEdit></MdEdit>
                    </IconButton>
                </Link>
            </div>
        }
    },
    {
        key: 'view',
        label: '',
        render: (b) => {
            return <div className="flex gap-8">
                <Link to={`/admin/group-trips/view/${b.id}`}>
                    <IconButton size="small">
                        <MdArticle></MdArticle>
                    </IconButton>
                </Link>
            </div>
        }
    },
]

const GroupTrips = ({ router: { queryParams } }) => {
    const [query, setQuery] = useState({
        page: queryParams.get("page") || 1,
        query: queryParams.get("query") || "",
    });

    const [trips, loadData, loading, error] = useRemoteResource(GroupTripService.getAll, query);

    useEffect(() => {
        // Обновляет URL и не перегружает страницу
        urlReplaceState(null, query);
    }, [query]);

    const updateSearch = (params) => {
        setQuery({
            ...query,
            ...params,
        });
    };


    const onPageChanged = (e, _page) => {
        updateSearch({ page: _page })
    }

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (trips.rows.length === 0) {
            return <div>Поездки отсутсвуют. Чтобы добавить поездку пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (trips.rows.length > 0) {
            const count = Math.ceil(trips?.count / LIMIT)
            const showPagination = count > LIMIT

            return <div>
                <AppTable columnConfig={columnConfig} data={trips.rows}></AppTable>
                <div>
                    {showPagination ? <Pagination className="m-t-32" page={query.page} count={count} onChange={onPageChanged} /> : null}
                </div>
            </div>

        }
    }
    return (
        <ListPage pageTitle="Список поездок">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(GroupTrips);
