import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { toDocumentFormatWithTime } from 'helpers/dateHelper'
import _ from 'lodash'
import AppTable from './AppTable/AppTable'
import { toCurrencyFormat } from 'helpers/utils'
import { OPERATION_TYPE } from 'enums/payments'
import { i18n } from 'data/i18n'
import { PAYMENT_STATUS } from 'data/paymentConts'
import { BookingStatusEnum } from 'data/bookingStatus'

export const PaymentCard = ({ booking, onClose, options }) => {
    const columnConfig = [
        {
            key: 'createdAt',
            label: 'Дата оплаты',
            formatter: (v) => toDocumentFormatWithTime(v)
        },
        {
            key: 'amount',
            label: 'Сумма оплаты',
            render: (i) => toCurrencyFormat(i.amount, i.currency)
        },
        {
            key: 'operationType',
            label: 'Тип операций',
            render: (i) => i.operationType ? i18n[i.operationType] : ""
        },
        {
            key: 'amountInUSD',
            label: 'Сумма оплаты в долларах',
            render: (i) => toCurrencyFormat(i.amountInUSD, 'USD')
        },
        {
            key: 'exchangeRate',
            label: 'По курсу',
            render: (i) => i.exchangeRate
        },
        {
            key: 'paymentMethod',
            label: 'Способ оплаты',
            formatter: (v) => v === 'cash' ? 'Наличные' : v
        },
        {
            key: 'status',
            label: 'Статус',
            formatter: (v) => PAYMENT_STATUS[v]
        },
        {
            key: 'comment',
            label: 'Комментарий',
        },
    ]
    const hideButtons = _.get(options, 'hideButtons')


    const totalPayed = () => {
        if (!booking.payments) {
            return 0
        }
        const total = booking.payments.filter(p => p.operationType === OPERATION_TYPE.pay).reduce((acc, cur) => {
            return Number(acc) + Number(cur.amountInUSD)
        }, 0)

        return Number(total).toFixed(2)
    }

    const totalRefund = () => {
        if (!booking.payments) {
            return 0
        }
        const total = booking.payments.filter(p => p.operationType === OPERATION_TYPE.refund).reduce((acc, cur) => {
            return Number(acc) + Number(cur.amountInUSD)
        }, 0)

        return Number(total).toFixed(2)
    }

    const remaining = () => {
        const result = Number(booking.totalPrice - totalPayed())
        if (result === 0) {
            return `0 $`
        }
        if (result < 0) {
            return `0 $ (+${Math.abs(result)} $)`
        }
        return toCurrencyFormat(result, 'USD')
    }

    const paymentButtons = () => {
        if (booking.bookingStatusID === BookingStatusEnum.CREATED) {
            return <Button onClick={() => onClose(OPERATION_TYPE.pay)}>Добавить платеж</Button>
        }
        if (booking.bookingStatusID === BookingStatusEnum.REFUND) {
            return <Button onClick={() => onClose(OPERATION_TYPE.refund)}>Возврат</Button>
        }
        if ([BookingStatusEnum.PARTIAL_PAYMENT].includes(booking.bookingStatusID)) {
            return <>
                <Button onClick={() => onClose(OPERATION_TYPE.pay)}>Добавить платеж</Button>
                <Button onClick={() => onClose(OPERATION_TYPE.refund)}>Возврат</Button>
            </>
        }

        if ([BookingStatusEnum.COMPLETED].includes(booking.bookingStatusID)) {
            return <Button onClick={() => onClose(OPERATION_TYPE.refund)}>Возврат</Button>
        }
        return <>
            <Button onClick={() => onClose(OPERATION_TYPE.pay)}>Добавить платеж</Button>
            <Button onClick={() => onClose(OPERATION_TYPE.refund)}>Возврат</Button>
        </>
    }

    const totals = () => {
        if (booking.bookingStatusID === BookingStatusEnum.REFUND) {
            return <div className='m-t-64 flex flex-col text-right'>
                <div>Общая стоимость тура: <span>{toCurrencyFormat(booking.totalPrice, 'USD')}</span></div>
                <div>Оплачено: <span>{toCurrencyFormat(totalPayed(), 'USD')}</span></div>
                <div>Сумма возврата: <span>{toCurrencyFormat(totalRefund(), 'USD')}</span></div>
            </div>
        }
        return <div className='m-t-64 flex flex-col text-right'>
            <div>Общая стоимость тура: <span>{toCurrencyFormat(booking.totalPrice, 'USD')}</span></div>
            <div>Оплачено: <span>{toCurrencyFormat(totalPayed(), 'USD')}</span></div>
            <div>Задолжность: <span>{remaining()}</span></div>
        </div>
    }

    return (
        <Card className='p-16 m-b-32'>
            <CardHeader title="История платежей"></CardHeader>
            <CardContent>
                <AppTable data={booking.payments} columnConfig={columnConfig}></AppTable>
                {totals()}
            </CardContent>
            {hideButtons ? null : <CardActions>
                {paymentButtons()}
            </CardActions>}
        </Card>
    )
}
