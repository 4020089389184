import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import attributesService from "services/admin/attributesService";
import { snack } from "components/Snack/Snack";

const AttributeForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { attribute },
}) => {
  const fieldsWithData = [
    {
      key: "photo",
      label: "Основное фото атрибута",
      inputType: inputTypeEnum.file,
    },
    {
      key: "name",
      label: "Название атрибута",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание атрибута",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "price",
      label: "Цена",
      inputType: inputTypeEnum.text,
      defaultValue: '0'
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      defaultValue: 'active',
      options: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'inactive',
          name: 'Неактивный'
        },
      ]
    }
  ]

  const onSubmit = async (formData) => {
    try {
      console.log("formData:", Object.fromEntries(formData));
      if (attribute) {
        await attributesService.put(id, formData)
        snack("Атрибут обновлен успешно!");
      } else {
        await attributesService.post(formData)
        snack("Атрибут создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={attribute ? 'Редактирование атрибута' : 'Создание атрибута'}>
      <AppForm onSubmit={onSubmit} data={attribute} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(AttributeForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['attribute'] = () => attributesService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
