import { CircularProgress, Pagination } from "@mui/material";
import { BookingsTable } from "components/BookingsTable";
import { urlReplaceState } from "helpers/url";
import withRouter from "hoc/withRouter";
import { useRemoteResource } from "hooks/useRemoteResource";
import { useEffect, useState } from "react";
import bookingsService from "services/admin/bookingsService";

export const CustomerOrders = ({ router: { queryParams, params } }) => {
    console.log(`params: `, params);

    const [query, setQuery] = useState({
        page: queryParams.get("page") || 1,
        query: queryParams.get("query") || "",
    });

    const customerID = params?.id
    const [orders, loadOrders, loading, error] = useRemoteResource(() => bookingsService.getAll({ buyerID: customerID }))
    console.log(`orders: `, orders);


    useEffect(() => {
        // Используется для обновления состояние url
        urlReplaceState(null, query);
    }, [query]);

    const updateSearch = (params) => {
        setQuery({
            ...query,
            ...params,
        });
    };

    const onPageChanged = (e, _page) => {
        updateSearch({ page: _page })
    }

    function buildContent() {
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }

        if (orders.rows.length === 0) {
            return <div>Заказы отсутсвуют у клиента</div>
        }
        const totalPages = Math.ceil(orders?.count / 30)

        return <div>
            <BookingsTable data={orders.rows}></BookingsTable>
            {totalPages > 1 ? <Pagination className="m-t-32" page={query.page} count={totalPages} onChange={onPageChanged} /> : null}
        </div>
    }

    return (
        <div className="m-t-32">
            <h3 className="m-b-16">История заказов</h3>
            {buildContent()}
        </div>
    )
}


export default withRouter(CustomerOrders)