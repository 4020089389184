import { createMarkup } from 'helpers/componentUtils';
import { Link } from 'react-router-dom';
import _ from 'lodash'
import { MdFlightTakeoff, MdOutlineHotel, MdPerson } from 'react-icons/md';
import { FaCarAlt } from 'react-icons/fa';
import { HajjImage } from 'components/HajjImage/HajjImage';
import { TourPeriod } from 'components/TourPeriod/TourPeriod';
import { toDocumentFormat } from 'helpers/dateHelper';

export default function TourPackageCard({ tourPackage, options, loadData }) {
    const cssClass = _.get(options, 'cssClass', '')
    return (
        <div className={cssClass}>
            <div className='flexr gap-16'>
                <div style={{ borderRadius: '8px', overflow: 'hidden', flex: 0.2 }}>
                    <HajjImage image={tourPackage.photo} />
                </div>
                <div className='profile' style={{ flex: 0.7 }}>
                    <Link to={`/admin/tour-packages/view/${tourPackage.id}`}>
                        <h3 className='m-b-8 fs-20'>{tourPackage.name} ({toDocumentFormat(tourPackage.startDate)}-{toDocumentFormat(tourPackage.endDate)})</h3>
                    </Link>
                    <div className='m-t-16 fs-16'>Цена: {tourPackage.price > 0 ? `${tourPackage.price}$ за взрослого человека` : 'Отсутствует'}</div>
                    <TourPeriod tourPackage={tourPackage}></TourPeriod>
                    <div className='m-t-8'>Статус: {tourPackage.status ? <span className='color-green'>Активный</span> : 'Неактивный'}</div>
                    <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(tourPackage.description)}></div>
                    <div className='tour-options m-t-32 fs-16 gap-4 flex flexw'>
                        <div className='flex gap-1'>
                            <MdOutlineHotel className='fs-20 color-green'></MdOutlineHotel>
                            <span className='fs-16'>Проживание</span>
                        </div>
                        <div className='flex gap-1'>
                            <MdFlightTakeoff className='fs-20 color-green'></MdFlightTakeoff>
                            <span className='fs-16'>Перелеты</span>
                        </div>
                        <div className='flex gap-1'>
                            <FaCarAlt className='fs-20 color-green'></FaCarAlt>
                            <span className='fs-16'>Трансфер</span>
                        </div>
                        <div className='flex gap-1'>
                            <MdPerson className='fs-20 color-green'></MdPerson>
                            <span className='fs-16'>Гид</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
