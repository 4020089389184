import { Alert, Button, CircularProgress } from "@mui/material";
import AppTable from "components/AppTable/AppTable";
import _ from "lodash";
import { useEffect, useState } from "react";
import customersService from "services/admin/customersService";

const CLIENT_ID = '1057883174825-dlu9c6bdti1ve8bsk4q3t10d252e0p5q.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCej4RfGO31tFGtJ1og7kLMQtX65-gcAHU';
const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

let tokenClient;
let accessToken = null;

const uploadStatus = {
  'fulfilled': 'Успешно',
  'rejected': 'Ошибка'
}

const customerTableColumn = {
  status: {
    key: 'status',
    label: 'Cтатус загрузки',
    render: (i) => {
      if(i.status === 'fulfilled'){
        return <span className="color-green">{uploadStatus[i.status]}</span>
      }
      return <span className="color-red">{uploadStatus[i.status]}</span>
    }
  },
  error: {
    key: 'error',
    label: 'Детали',
    render: (i) => <span className="fs-12">{i.error}</span>
  }
}

const CustomersImportFromGoogleSheet = () => {
  const [data, setData] = useState(null)
  const [columnConfig, setColumnConfig] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // Инициализация аутентификации
    initializeAuth();
  }, [])

  // 1. Инициализация Google Identity Services
  function initializeAuth() {
    tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        accessToken = response.access_token;
        loadPicker();
      },
    });
  }

  // 2. Запуск выбора файла через Google Picker
  function loadPicker() {
    window.gapi.load('picker', () => {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(accessToken)
        .setDeveloperKey(API_KEY)
        .setCallback(async (data) => {
          if (data.action === window.google.picker.Action.PICKED) {
            const fileId = data.docs[0].id;
            console.log('Selected File ID:', fileId);

            try {
              setIsLoading(true)
              let result = await customersService.importCustomersFromGoogleSheet(fileId)
              result = result.data
              console.log(`result" `, result);

              result.customersCSV = result.customersCSV.map((c, inx) => {
                return {
                  status: result.createdCustomers[inx].status,
                  error: _.get(result, `createdCustomers[${inx}].reason`),
                  ...c
                }
              })

              if (result.customersCSV && result.customersCSV.length > 0) {
                // Формируем шапку таблицы
                const row = result.customersCSV[0]
                let _columnConfig = Object.keys(row).map(x => {
                  let key = x
                  let label = x
                  // По клучу получаем описание колонки
                  if (customerTableColumn[key]) {
                    return customerTableColumn[key]
                  }

                  return {
                    key,
                    label
                  }
                })
                setColumnConfig(_columnConfig)
                console.log(`_columnConfig:`, _columnConfig);
              }

              setData(result)
              console.log(`Data:`, result);
            } catch (error) {
              console.error('Error while importing customers from Google Sheet', error.message)
              alert('Ошибка во время загрузки клиентов')
            } finally {
              setIsLoading(false)
            }
          }
        })
        .build();
      picker.setVisible(true);
    });
  }


  function selectFile() {
    if (!accessToken) {
      tokenClient.requestAccessToken();
    } else {
      loadPicker();
    }
  }


  return (
    <div>
      <h3 className="mb-4">
        Здесь вы можете загружать клиентов через Google Sheet файл.
      </h3>
      <Alert color="info" className="m-b-16">
        Перед загрузкой клиентов через Google Sheet убедитесь, что данные корректны, не содержат дубликатов и номера телефонов указаны для каждого клиента в международном формате без плюса(+), например для Казахстана: 77772001991. В случае наличия дублирующих записей система примет только первую запись.
      </Alert>
      <p className="mb-8">Пожалуйста, выберите файл, после нажмите на кнопку загрузить.</p>
      <form encType="multipart/form-data">
        {isLoading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
          <Button type="button" variant="contained" onClick={selectFile}>Выбрать файл</Button>}
      </form>

      {data?.customersCSV ? <div>
        <h3 className="m-t-32 m-b-16">Результат загрузки</h3>
        <Alert className="m-b-16" color="info">
          После загрузки клиентов через Google Sheet, вы можете увидеть результат загрузки снизу. Пожалуйста, проверьте колонку <strong>"Статус загрузки"</strong> для каждого клиента. Если вы видете статус загрузки <strong>Ошибка</strong> это значит что данный клиент не загрузился в систему, детали ошибки вы можете посмотреть в следующей колонке "Детали"
          Пожалуйста, исправьте данные ошибочного клиента в Google Sheet, затем загрузите файл еще раз.
        </Alert>
        <AppTable columnConfig={columnConfig} data={data.customersCSV}></AppTable>
      </div> : null}
    </div>
  );
};
export default CustomersImportFromGoogleSheet;
