import { Outlet } from "react-router-dom";
import ListPage from "./CustomerList";
import AddBulk from "./CustomersAddBulk";
import Customer from "./CustomerView";
import CustomerForm from "./CustomerForm";
import { ExpiredPassport } from "./ExpiredPassport";
import { ExpiredVisas } from "./ExpiredVisas";
import Debtor from "./Debtor";
import CustomersImportFromGoogleSheet from "./CustomersImportFromGoogleSheet";

export const adminCustomersRoutes = [
  {
    path: "customers",
    element: Outlet,
    children: [
      {
        path: "",
        redirect: "all"
      },
      {
        path: "all",
        element: ListPage,
      },
      {
        path: "add",
        element: CustomerForm,
      },
      {
        path: "passport",
        element: ExpiredPassport,
      },
      {
        path: "visas",
        element: ExpiredVisas,
      },
      {
        path: "edit/:id",
        element: CustomerForm,
      },
      {
        path: "view/:id",
        element: Customer,
      },
      {
        path: "add-bulk",
        element: AddBulk,
      },
      {
        path: "import-from-google-sheet",
        element: CustomersImportFromGoogleSheet,
      },
      {
        element: Debtor,
        path: "debtors",
        label: "Реестр должникиов",
      },
    ],
  },
];
