import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { snack } from "components/Snack/Snack";
import flightsService from "services/admin/flightsService";
import airlinesService from "services/admin/airlinesService";
import _ from "lodash";
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { airportsService } from "services/airports.service";
import { useEffect } from "react";
import moment from "moment";


const statuses = [
  {
    id: 'active',
    name: 'Активный'
  },
  {
    id: 'inactive',
    name: 'Неактивный'
  },
]

export const weeks = [
  {
    id: 'monday',
    name: 'Понидельник'
  },
  {
    id: 'tuesday',
    name: 'Вторник'
  },
  {
    id: 'wednesday',
    name: 'Среда'
  },
  {
    id: 'thursday',
    name: 'Четверг'
  },
  {
    id: 'friday',
    name: 'Пятница'
  },
  {
    id: 'saturday',
    name: 'Суббота'
  },
  {
    id: 'sunday',
    name: 'Воскресенье'
  },
]

const FlightForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { flight, airlines, airports },
}) => {

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
    watch,
    unregister
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      airlineID: _.get(flight, 'airlineID', ''),
      flightNumber: _.get(flight, 'flightNumber', ''),
      departureAirportID: _.get(flight, 'departureAirportID', ''),
      departureTime: _.get(flight, 'departureTime', ''),
      arrivalAirportID: _.get(flight, 'arrivalAirportID', ''),
      arrivalTime: _.get(flight, 'arrivalTime', ''),
      durationHours: _.get(flight, 'durationHours', '') ? _.get(flight, 'durationHours', '') : '',
      durationMinutes: _.get(flight, 'durationMinutes', '') ? _.get(flight, 'durationMinutes', '') : '',
      scheduleDays: _.get(flight, 'scheduleDays', { monday: true }),
      // scheduleDays: { monday: true, friday: true },
      description: _.get(flight, 'description', ''),
      status: _.get(flight, 'status', 'active')
    },
  });

  const departureTime = watch('departureTime')
  const durationHours = watch('durationHours')
  const durationMinutes = watch('durationMinutes')
  const departureAirportID = watch('departureAirportID')
  const arrivalAirportID = watch('arrivalAirportID')

  useEffect(() => {
    if (departureAirportID && arrivalAirportID && departureTime && durationHours) {
      const departureAirport = airports.data.find(a => a.id === departureAirportID)
      const arrivalAirport = airports.data.find(a => a.id === arrivalAirportID)
      const departureAirportTimezone = Number(arrivalAirport.timezone) - Number(departureAirport.timezone)

      const arrivalTime = moment()
        .set('hour', 0)
        .set('minutes', 0)
        .add(departureTime)
        .add(durationHours, 'hours')
        .add(durationMinutes, 'minutes')
        .add(departureAirportTimezone, 'hours')
        .format('HH:mm')

      setValue('arrivalTime', arrivalTime, {
        shouldTouch: true,
        shouldDirty: true
      })
    }
  }, [durationHours, durationMinutes, departureTime, departureAirportID, arrivalAirportID])


  const onSubmit = async (flightInput) => {
    try {
      if (flight) {
        await flightsService.put(id, flightInput)
        snack("Рейс обновлен успешно!");
      } else {
        await flightsService.post(flightInput)
        snack("Рейс создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={flight ? 'Редактирование рейса' : 'Создание рейса'}>
      <Alert color="info" className="m-b-32">Перед созданием: Пожалуйста для удобства скопируйте данные из <strong>посадочного талона</strong></Alert>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller control={control} name="airlineID" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Пожалуйтса, выберите авиакомпанию</InputLabel>
            <Select label="Пожалуйтса, выберите авиакомпанию" {...field} value={field.value || ''}>
              {airlines ?
                airlines.data.map(t => {
                  return <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                })
                : []}
            </Select>
          </FormControl>
        }}></Controller>
        <TextField
          fullWidth {...register('flightNumber', { required: true })}
          type='text'
          label='Номер рейса *'
        ></TextField>
        <h3 class="m-b-16">Информация о вылете</h3>
        <div>
          <Controller control={control} name="departureAirportID" rules={{ required: true }} render={({ field }) => {
            return <FormControl fullWidth>
              <InputLabel>Пожалуйтса, выберите аэропорт вылета</InputLabel>
              <Select label="Пожалуйтса, выберите аэропорт вылета" {...field} value={field.value || ''}>
                {airports ?
                  airports.data.map(t => {
                    return <MenuItem key={t.id} value={t.id}>
                      {t.name} - {t.airportCode}
                    </MenuItem>
                  })
                  : []}
              </Select>
            </FormControl>
          }}></Controller>
        </div>
        <TextField
          fullWidth {...register('departureTime', { required: true })}
          type='time'
          InputLabelProps={{ shrink: true }} 
          label='Время вылета(по местному времени) *'
        ></TextField>
        <div>
          <Controller control={control} name="arrivalAirportID" rules={{ required: true }} render={({ field }) => {
            return <FormControl fullWidth>
              <InputLabel>Пожалуйтса, выберите аэропорт прилета</InputLabel>
              <Select label="Пожалуйтса, выберите аэропорт вылета" {...field} value={field.value || ''}>
                {airports ?
                  airports.data.map(t => {
                    return <MenuItem key={t.id} value={t.id}>
                      {t.name} - {t.airportCode}
                    </MenuItem>
                  })
                  : []}
              </Select>
            </FormControl>
          }}></Controller>
        </div>
        <div>
          <p>Укажите длительность полета в формате час и минута</p>
          <div className="flex gap-8">
            <Controller control={control} name="durationHours" rules={{ required: true }} render={({ field }) => {
              return <FormControl style={{ width: 150 }}>
                <InputLabel>Час</InputLabel>
                <Select label="Час" {...field} value={field.value || ''}>
                  {new Array(24).fill('h').map((h, inx) => {
                    return <MenuItem key={inx + 1} value={inx + 1}>
                      {inx + 1} час
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            }}></Controller>
            <Controller control={control} name="durationMinutes" rules={{ required: true }} render={({ field }) => {
              return <FormControl style={{ width: 150 }}>
                <InputLabel>Минута</InputLabel>
                <Select label="Минута" {...field} value={field.value || ''}>
                  {new Array(12).fill('h').map((h, inx) => {
                    return <MenuItem key={`${inx * 5}`} value={`${inx * 5}`}>
                      {inx * 5} минута
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            }}></Controller>
          </div>
        </div>
        <label htmlFor="arrivalTime">Время прилета(по местному времени) *</label>
        <TextField
          id="arrivalTime"
          fullWidth {...register('arrivalTime', { required: true })}
          type='time'
          disabled          
        ></TextField>

        <div className="m-b-32">
          <div className="m-b-16">Расписание:</div>
          <div className="flex gap-2">
            {weeks.map((d, inx) => {
              return <span key={inx}>
                <label className="chip-label" htmlFor={d.id}>
                  {d.name}
                  <input
                    {...register(`scheduleDays.${d.id}`)}
                    id={d.id}
                    type="checkbox"
                    name={`scheduleDays.${d.id}`}
                    style={{ display: 'none' }}
                  />
                </label>
              </span>
            })
            }
          </div>
        </div>

        <TextField
          multiline
          rows={3}
          fullWidth {...register('description')}
          type='text'
          label='Краткое описание'
        ></TextField>
        <Controller control={control} name="status" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Статус</InputLabel>
            <Select label="Статус" {...field} value={field.value || ''}>
              {statuses.map(s => {
                return <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              })}
            </Select>
          </FormControl>
        }}></Controller>

        <Button variant="contained" type="submit" disabled={!isValid}>
          {flight ? <span>Обновить рейс</span> : <span>Создать рейс</span>}
        </Button>
        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
      </form>
    </CrudPage >
  );
};
export default withRouter(
  withRemoteDataAndSpinner(FlightForm, (router) => {
    let requests = {
      airlines: () => airlinesService.getAll(),
      airports: () => airportsService.getAll()
    }
    if (router.params.id) {
      requests['flight'] = () => flightsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
