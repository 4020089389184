import { Alert, Button, CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { toFormData } from "helpers/formUtils";
import { useRef, useState } from "react";
import customersService from "services/admin/customersService";

const CustomersAddBulk = () => {
  const fileRef = useRef();
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [preview, setPreview] = useState()

  const onSubmit = async (e) => {
    setIsLoading(true)
    try {
      e.preventDefault();

      console.log("onSubmit: ", fileRef.current.files);
      if (!fileRef.current || !fileRef.current?.files) {
        snack("Пожалуйста выберите csv файл");
        return;
      }

      const file = fileRef.current.files[0];
      if (!file) {
        return snack('Файл не выбран')
      }
      const formedData = toFormData({ file });
      await customersService.bulkCreate(formedData);
      snack("Все клиенты успешно загружены");
      setTimeout(() => {
        window.history.back();
      }, 1000);
    } catch (ex) {
      console.log("ex: ", ex);
      setError(ex?.message)
    } finally {
      setIsLoading(false)
    }
  };


  function onFileChanged(e) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const content = e.target.result;
        console.log("CSV Content:", content);

        // Process CSV content (e.g., split by lines and commas)
        const rows = content.split("\n").map(row => row.split(","));
        console.log("Parsed CSV Data:", rows, rows.length);

        if (rows) {
          setPreview(rows)
        }
      };

      reader.onerror = function (e) {
        console.error("Error reading file:", e.target.error);
      };

      reader.readAsText(file);
    } else {
      alert('CSV файл не выбран')
    }
  }

  return (
    <div>
      <h3 className="mb-4">
        Здесь вы можете загружать клиентов через csv файл.
      </h3>
      <Alert color="warning" className="mb-8">Пожалуйста, выберите csv файл, проверьте данные и после нажмите на кнопку загрузить. Пример csv файла вы можете найти по ссылке:
        <a
          href="/csv/clients.csv"
          className="color-blue"
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          csv файла
        </a>{" "}
      </Alert>
      <form encType="multipart/form-data" onSubmit={onSubmit}>
        <input type="file" accept=".csv" size="100000" ref={fileRef} onChange={onFileChanged} />
        {preview && preview?.length > 1 ? <section className="preview">
          <h4 className="m-t-32">Содержимое CSV файла: </h4>
          <table className="table fs-12 m-t-32">
            <tr>
              {preview[0].map(h => <td>{h}</td>)}
            </tr>
            <tbody>
              {preview.filter((r, inx) => inx !== 0).map(r => {
                return <tr>
                  {r.map(c => <td>{c}</td>)}
                </tr>
              })}
            </tbody>
          </table>
          <Alert color="info" className="m-t-32">
            Перед загрузкой клиентов через CSV файл убедитесь, что данные корректны, не содержат дубликатов и номера телефонов указаны для каждого клиента в международном формате без плюса(+), например для Казахстана: 77772001991. В случае наличия дублирующих записей система примет только первую запись.
            Затем нажмите на кнопку "Загрузить клиентов"
          </Alert>
        </section> : null}
        <section className="m-t-32">
          {error ? <div className="color-red">{error}</div> : null}
        </section>

        {isLoading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
          preview ? <Button variant="contained" type="submit">
            Загрузить клиентов
          </Button> : null
        }
      </form>
    </div>
  );
};
export default CustomersAddBulk;
