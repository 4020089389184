import { Card, CardContent, CircularProgress } from '@mui/material';
import { useRemoteResource } from 'hooks/useRemoteResource';
import React from 'react'
import logsService from 'services/admin/logsService';
import AppTable from './AppTable/AppTable';
import { toDate } from 'helpers/dateHelper';
import withRouter from 'hoc/withRouter';
import _ from 'lodash';

const columnConfig = [
    {
        key: 'id',
        label: 'ID'
    },
    {
        key: 'description',
        label: 'Описание'
    },
    {
        key: 'ipAddress',
        label: 'IP Address'
    },
    {
        key: 'createdAt',
        label: 'Дата',
        formatter: (v) => toDate(v)
    },
    {
        key: 'userAgent',
        label: 'Устройство'
    },
]

const AuthLogs = ({ router }) => {
    const memberID = _.get(router, 'params.id')
    console.log(`memberID: `, router);
    
    const [authLogs, loadAuthLogs, loading, error] = useRemoteResource(() => logsService.getUserLogs('LOGIN', memberID))

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <section className='m-t-32'>
            <h3 className='m-b-16'>Журнал входов</h3>
            <AppTable columnConfig={columnConfig} data={authLogs.data}></AppTable>
        </section>
    )
}

export default withRouter(AuthLogs)