import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import OrgCard from "components/OrgCard/OrgCard";
import organizationsSAService from "services/superadmin/organizationsSAService";

const OrganizationList = () => {
    const [data, loadData, loading, error] = useRemoteResource(organizationsSAService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Организация отсутсвуют. Чтобы добавить пожалуйста нажмите на кнопку "Добавить"</div>
        }
        if (data.data.length > 0) {
            return data.data.map((org, inx) => {
                return <OrgCard key={inx} org={org} loadData={loadData}></OrgCard>
            })
        }
    }
    return (
        <ListPage pageTitle="Список организации">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(OrganizationList);
