import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import usersService from 'services/usersService';
import MemberCard from 'components/MemberView';

function Profile({ data }) {
    let member
    if (data.user.members) {
        member = data.user.members.find(x => x.orgID === localStorage.getItem('orgID'))
    }
    console.log("Member: ", member)

    return (
        <MemberCard member={member}></MemberCard>
    )
}
export default withRouter(
    withRemoteDataAndSpinner(Profile, (router) => {
        return useLoadAll({
            user: () => usersService.me(),
        });
    })
);
