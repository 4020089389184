import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { phoneToUI } from 'hajjtravel-utils'
import { MdVerified } from 'react-icons/md'
import membersService from 'services/admin/membersService'
import { snack } from './Snack/Snack'

export default function MemberCard({ member }) {    
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить сотрудника?')

            if (isConfirmed) {
                await membersService.delete(item.id);
                snack('Сотрудник успешно удалена!')
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
        } catch (ex) {
            console.error('При удаление сотрудника вышла ошибка:', ex?.message)
        }
    };

    return (
        <Card>
            <CardContent>
                <div className='flex gap-8'>
                    <div>
                        <img style={{ width: 100, borderRadius: '50%' }} src='/media/images/avatar_m.jpg' alt="" />
                    </div>
                    <div className='profile'>
                        <h3>{member.name}</h3>
                        {member.user?.phone && <div className='m-t-16 flex gap-2'>
                            <span className='color-blue'>+{member.user?.phonePrefix}{phoneToUI(member.user.phone)}</span>
                            <MdVerified className='color-blue fs-16'></MdVerified>
                        </div>}
                        <div className='m-t-8'>{member.user.email}</div>
                        <div className='m-t-16'>Является сотрудником: {member && member.org.name}</div>
                        <div className='m-t-8'>
                            <span>Имеет роль: </span>
                            <span>{member && member.role?.name}</span>
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardActions className='justify-end'>
                <Link to={`/admin/members/view/${member.id}`}>
                    <Button size="small">Карточка сотрудника</Button>
                </Link>
                <Link to={`/admin/members/edit/${member.id}`}>
                    <Button size="small">Редактировать</Button>
                </Link>
                <Link onClick={() => onDelete(member)}>
                    <Button size="small">Удалить</Button>
                </Link>
            </CardActions>
        </Card>
    )
}
