import { Card, CardContent, CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { CURRENCY, toCurrencyFormat } from "helpers/utils";
import { useEffect, useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { MdGroup, MdPayment, MdPayments } from "react-icons/md";
import paymentsService from "services/admin/paymentsService";

const RevenueTotalAmount = ({ month }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await paymentsService.getRevenueTotalAmount({ month })
      console.log(`RevenueTotalAmount:`, result.data);      
      setData(result.data?.sum)
    } catch (ex) {
      console.error(`CustomersCount ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }} className="stat-card">
      <CardContent>
        <div className="text-center">
          <MdPayments size="42" style={{ color: 'rgba(83, 155, 255)' }}></MdPayments>
          <h4 className="text-info">Общая выручка, $</h4>
          <h4 className="text-info mt-1">{toCurrencyFormat(data, CURRENCY.USD) || 0}</h4>
        </div>
      </CardContent>
    </Card>
  );
};

export default RevenueTotalAmount
