import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import { phoneToUI } from 'hajjtravel-utils'
import { MdVerified } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { getOrg } from 'redux/selectors/getOrg'
import AuthLogs from './AuthLogs'

export default function MemberView({ member }) {
    const authMember = useSelector(getOrg);

    if (!member) {
        return <div>Ошибка: Сотрудник не существует</div>
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div>
                            <img style={{ width: 100, borderRadius: '50%' }} src='/media/images/avatar_m.jpg' alt="" />
                        </div>
                        <div className='profile'>
                            <h3>{member.name}</h3>
                            {member.user?.phone && <div className='m-t-16 flex gap-2'>
                                <span className='color-blue'>+{member.user?.phonePrefix}{phoneToUI(member.user.phone)}</span>
                                <MdVerified className='color-blue fs-16'></MdVerified>
                            </div>}
                            <div className='m-t-8'>{member.user.email}</div>
                        </div>
                    </div>
                </CardContent>
                {authMember.roleID === "viewer" ? null : <CardActions className='justify-end'>
                    <Link to={`/admin/members/edit/${member.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                </CardActions>}
            </Card>

            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Является сотрудником</h3>
                    <div className='m-t-8'>
                        <span>Наименование организации: </span>
                        <span>{member && member.org.name}</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Имеет роль: </span>
                        <span>{member && member.role?.name}</span>
                    </div>
                </CardContent>
            </Card>

           <AuthLogs></AuthLogs>

            {/* <Card className='m-t-32'>
                <CardContent>
                    <ManagerOrders memberID={member.id}></ManagerOrders>
                </CardContent>
            </Card> */}
        </div>
    )
}
