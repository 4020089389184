import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class CustomersAdminService extends BaseService<any> {
  constructor() {
    super("customers");
  }

  bulkCreate = async (data: any) => {
    return appAxios.post(`${this.pathWithOrg()}/bulk-create`, data);
  };
  importCustomersFromGoogleSheet = async (fileID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/google-import-customers?fileID=${fileID}`);
  };

  getExpiredPassport = async () => {
    return appAxios.get(`${this.pathWithOrg()}/expired-documents/passport`);
  }
  getExpiredVisas = async () => {
    return appAxios.get(`${this.pathWithOrg()}/expired-documents/visas`);
  }
  getCustomersGenders = async () => {
    return appAxios.get(`${this.pathWithOrg()}/stats/genders`);
  }
  getCustomersCitizenship = async () => {
    return appAxios.get(`${this.pathWithOrg()}/stats/citizenship`);
  }
}

export default new CustomersAdminService();
