import "./stats.scss";
import UsersCount from "./components/CustomersCount";
import PaymentMethodStats from "./components/PaymentMethods";
import OrdersCount from "./components/OrdersCount";
import TourCount from "./components/TourCount";
import OrdersByTourpackages from "./components/OrdersByTourpackages";
import GroupTripsCount from "./components/GroupTripsCount";
import TotalPaymentsByTourpackages from "./components/TotalPaymentsByTourpackages";
import RevenueMonthly from "./components/RevenueMonthly";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { urlReplaceState } from "helpers/url";
import moment from "moment";
import RevenueTotalAmount from "./components/RevenueTotalAmount";
import RefundTotalAmount from "./components/RefundTotalAmount";
import NetRevenueTotalAmount from "./components/NetRevenueTotalAmount";
import CustomerGenders from "./components/CustomerGenders";
import CustomerCityzenship from "./components/CustomerCityzenship";

export const periods = [
  {
    id: 1,
    label: 'За месяц'
  },
  {
    id: 3,
    label: 'За три месяца'
  },
  {
    id: 6,
    label: 'За полгода'
  },
  {
    id: 12,
    label: 'За один год'
  },
  {
    id: 60,
    label: 'За пять лет'
  }
]

const StatsPage = () => {
  const [month, setMonth] = useState(12)

  useEffect(() => {
    urlReplaceState(null, { month });
  }, [month]);

  function showPeriod() {
    if (month) {
      return `Данные отображаются с ${moment().subtract(month, 'month').format('YYYY.MM.DD')} по ${moment().format('YYYY.MM.DD')}`
    }
    return null
  }

  return (
    <div>
      <div className="flex flexr flex-between flex-center m-b-32">
        <h3>Аналитика</h3>
        <div className="text-right">
          <div>
            <label>Отображать данные: </label>
            <Select defaultValue={month} onChange={(e) => setMonth(e.target.value)}>
              {periods.map((p, inx) => {
                return <MenuItem key={inx} value={p.id}>{p.label}</MenuItem>
              })}
            </Select>
          </div>
          <div className="m-t-16 color-gray">{showPeriod()}</div>
        </div>
      </div>
      <div className="flexr stats" style={{ gap: "16px" }}>
        <RevenueTotalAmount month={month}></RevenueTotalAmount>
        {/* <NetRevenueTotalAmount month={month}></NetRevenueTotalAmount> */}
        <RefundTotalAmount month={month}></RefundTotalAmount>
        <UsersCount month={month} />
        <TourCount month={month} />
        <OrdersCount month={month} />
        <GroupTripsCount month={month} />
      </div>
      <section className="m-t-32 m-b-32 flexr gap-16">
        <PaymentMethodStats month={month} />
        <RevenueMonthly month={month}></RevenueMonthly>
      </section>
      <section>
        <OrdersByTourpackages month={month}></OrdersByTourpackages>
      </section>
      <section className="m-t-16">
        <TotalPaymentsByTourpackages month={month}></TotalPaymentsByTourpackages>
      </section>
      <section className="m-t-32">
        <h2 className="m-b-16">Узнайте больше о своих клиентах</h2>
        <div className="flexr gap-32 customers-stats">
          <CustomerGenders month={month} />
          <CustomerCityzenship month={month} />
        </div>
      </section>
    </div>
  );
};

export default StatsPage;
