import _ from 'lodash';
import React, { forwardRef } from 'react'
import { snack } from './Snack/Snack';

export const FileField = forwardRef(({ label, name, value, onChange, error, defaultValue }, ref) => {
    console.log(`value: `, value);
    
    return (
        <div className="flex flex-col field-file">
            <div className="preview m-t-16">
                <label>{label}</label>
                {defaultValue ? <div style={{ width: 100, height: 'auto', marginBottom: "1rem" }}>
                    <img src={defaultValue} alt="" />
                </div> : null}
            </div>
            <div>
                <input
                    type="file"
                    name={name}
                    placeholder={label}
                    ref={ref}
                    onChange={(e) => {
                        // const file = document.querySelector(`#${f.key}`).files[0];
                        const file = e.target.files[0]
                        const max = 5 * 1000 * 1000; // 5MB
                        console.log("File:", file);

                        if (file && file.size > max) {
                            snack(
                                "Вы не можете загружать большие файлы! Пожалуйста используйте файл маленького размера"
                            );
                        }

                        const fileReader = new FileReader();
                        const previewNode = e.target.parentNode.parentNode.querySelector('.preview')
                        if (!previewNode.querySelector('img')) {
                            const img = document.createElement('img')
                            const imgWrapper = document.createElement('div')
                            imgWrapper.style = 'width: 100px; height: auto; margin-top: 1rem;'
                            imgWrapper.appendChild(img)
                            previewNode.appendChild(imgWrapper)
                        }
                        console.log("document.parentElement:", previewNode);

                        const previewImage = previewNode.querySelector('img')

                        fileReader.onload = event => {
                            previewImage.setAttribute('src', event.target.result);
                        }
                        fileReader.readAsDataURL(file);
                        onChange(e)
                    }}
                />
            </div>
        </div>
    )
})
