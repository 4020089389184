import { toQueryParams } from "helpers/url";
import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class PaymentsAdminService extends BaseService<any> {
  constructor() {
    super(`payments`);
  }

  getPaymentsMethodCounts = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/stats/paymentMethod?${qp}`);
  };
  getRevenueByMonth = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/stats/revenueMonthly?${qp}`);
  };
  getRevenueTotalAmount = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/stats/revenueTotalAmount?${qp}`);
  };
  // Чистая выручка
  getNetRevenueTotalAmount = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/stats/netRevenueTotalAmount?${qp}`);
  };
  getRefundTotalAmount = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/stats/refundTotalAmount?${qp}`);
  };
}

export default new PaymentsAdminService();
