import { snack } from "components/Snack/Snack";
import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import withRouter from "hoc/withRouter";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import usersSAService from "services/superadmin/usersSAService";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";

/**
 * - Подумать как сделать страну и город в отдельный компонент
 */
const UserForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { user },
}) => {

  const fieldsWithData = [
    {
      key: "name",
      label: "ФИО",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "phone",
      label: "Телефон",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "phonePrefix",
      label: "Код страны",
      inputType: inputTypeEnum.text,
    },
    {
      key: "email",
      label: "Почта",
      inputType: inputTypeEnum.text,
    },
  ]

  const onSubmit = async (formData) => {
    try {
      const countryInput = Object.fromEntries(formData)
      if (user) {
        await usersSAService.put(id, countryInput)
        snack("Пользователь обновлен успешно!");
      } else {
        await usersSAService.post(countryInput)
        snack("Пользователь создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={user ? 'Редактирование пользовател]' : 'Создание пользовател]'}>
      <AppForm onSubmit={onSubmit} data={user} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};

export default withRouter(
  withRemoteDataAndSpinner(UserForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['user'] = () => usersSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
