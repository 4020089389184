import React from 'react'
import AppTable from './AppTable/AppTable';
import { Link } from 'react-router-dom';
import { toDate, toDocumentFormat } from 'helpers/dateHelper';
import moment from 'moment';
import { toCurrencyFormat } from 'helpers/utils';
import { BookingStatus } from './BookingStatus';
import { BookingStatusEnum } from 'data/bookingStatus';
import { IconButton, Pagination } from '@mui/material';
import { MdArticle, MdEdit } from 'react-icons/md';

const columnConfig = [
    {
        key: 'id',
        label: '#',
        render: (b) => {
            return <Link className="color-blue" to={`/admin/bookings/view/${b.id}`}>
                {b.id}
            </Link>
        }
    },
    {
        key: 'createdAt',
        label: 'Дата создания',
        formatter: (v) => toDate(v)
    },
    {
        key: '',
        label: 'Турпакет',
        render: (i) => {
            return <Link to={`/admin/tour-packages/view/${i.tourPackageID}`}>
                {i.tourPackage?.name} <span className="fs-12">({toDocumentFormat(i.tourPackage?.startDate)}, {moment(i.tourPackage.endDate).diff(moment(i.tourPackage.startDate), 'days')} дней)</span>
            </Link>
        }
    },
    {
        key: 'totalPrice',
        label: 'Общая стоимость',
        render: (i) => toCurrencyFormat(i.totalPrice, i.currency)
    },
    {
        key: 'bookingStatusID',
        label: 'Статус',
        render: (i) => <BookingStatus bookingStatus={i.bookingStatus}></BookingStatus>
    },
    {
        key: 'buyer',
        label: 'Покупатель',
        render: (i) => i.buyer?.name
    },
    {
        key: 'customers',
        label: 'Туристы',
        render: (b) => {
            console.log(`b.customers: `, b);

            if (!b.customers) {
                return ""
            }
            if (b.customers?.length > 3) {
                return <span>Больше 3х туристов</span>
            }
            return b.customers?.map(c => {
                return c.name
            }).join(', ')
        }
    },
    {
        key: 'edit',
        label: '',
        render: (b) => {
            const buttons = []
            if (b.bookingStatusID === BookingStatusEnum.CREATED) {
                const editButton = <Link to={`/admin/bookings/edit/${b.id}`}>
                    <IconButton size="small">
                        <MdEdit></MdEdit>
                    </IconButton>
                </Link>
                buttons.push(editButton)
            }
            return <div className="flex gap-8">{buttons.map(ab => ab)}</div>
        }
    },
    {
        key: 'view',
        label: '',
        render: (b) => {
            const buttons = []
            const viewButton = <Link to={`/admin/bookings/view/${b.id}`}>
                <IconButton size="small">
                    <MdArticle></MdArticle>
                </IconButton>
            </Link>
            buttons.push(viewButton)

            return <div className="flex gap-8">{buttons.map(ab => ab)}</div>
        }
    },
]

export const BookingsTable = ({ data }) => {
    return (
        <div>
            <AppTable data={data} columnConfig={columnConfig}></AppTable>
        </div>
    )
}
