import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCej4RfGO31tFGtJ1og7kLMQtX65-gcAHU",
  authDomain: "hajjtravel-api.firebaseapp.com",
  projectId: "hajjtravel-api",
  storageBucket: "hajjtravel-api.appspot.com",
  messagingSenderId: "1057883174825",
  appId: "1:1057883174825:web:31f763aab06cb9b07476b8",
  measurementId: "G-X2CJFXTW6D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Working with strorage
const storage = getStorage();
const storageRef = ref(storage);

// Authenticated with Firebase
const auth = getAuth(app);

export { auth, storageRef };
export default app;
