import React from 'react'

export const PrivacyPolicy = () => {
    return (
        <div>
            <div class="container">
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date:</strong> 01.10.2024</p>

                <h2>Introduction</h2>
                <p>Welcome to the Travel Agency Dashboard. We value your privacy and are committed to
                    protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and
                    safeguard your information when you use our dashboard. Please read this policy carefully to understand our
                    practices regarding your personal data.</p>

                <h2>1. Information We Collect</h2>
                <h3>1.1 Personal Information</h3>
                <ul>
                    <li>Name, email address, phone number, and contact details.</li>
                    <li>Payment details (e.g., credit card or bank account information).</li>
                    <li>Travel preferences, booking history, and customer feedback.</li>
                </ul>

                <h3>1.2 Non-Personal Information</h3>
                <ul>
                    <li>Browser type, operating system, and device information.</li>
                    <li>IP address and geolocation data.</li>
                    <li>Usage data (e.g., pages visited, time spent on the dashboard).</li>
                </ul>

                <h3>1.3 Information Provided by You</h3>
                <ul>
                    <li>Documents for visa processing (e.g., passport copies, travel itineraries).</li>
                    <li>Customer inquiries and support requests.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <ul>
                    <li>To provide and manage travel bookings, payments, and related services.</li>
                    <li>To communicate with you regarding bookings, updates, and support.</li>
                    <li>To personalize user experience based on travel preferences.</li>
                    <li>To process payments and issue invoices or receipts.</li>
                    <li>To comply with legal obligations (e.g., visa documentation).</li>
                    <li>To improve our dashboard's performance and user experience.</li>
                </ul>

                <h2>3. Information Sharing and Disclosure</h2>
                <p>We do not sell or rent your personal information. However, we may share your information with:</p>
                <ul>
                    <li><strong>Service Providers:</strong> Third-party vendors (e.g., payment processors, airline booking
                        systems).</li>
                    <li><strong>Travel Partners:</strong> Airlines, hotels, and other travel-related entities to fulfill your
                        bookings.</li>
                    <li><strong>Legal Authorities:</strong> To comply with applicable laws or respond to valid legal requests.
                    </li>
                    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your data
                        may be transferred.</li>
                </ul>

                <h2>4. Data Security</h2>
                <p>We implement appropriate technical and organizational measures to protect your personal data, including:</p>
                <ul>
                    <li>Encryption of sensitive information.</li>
                    <li>Secure server storage and access controls.</li>
                    <li>Regular security audits and monitoring.</li>
                </ul>

                <h2>5. Data Retention</h2>
                <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy,
                    unless a longer retention period is required by law.</p>

                <h2>6. Your Rights</h2>
                <p>Depending on your location, you may have the following rights:</p>
                <ul>
                    <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
                    <li><strong>Correction:</strong> Update or correct inaccurate information.</li>
                    <li><strong>Deletion:</strong> Request the deletion of your personal data.</li>
                    <li><strong>Objection:</strong> Object to certain data processing practices.</li>
                    <li><strong>Data Portability:</strong> Request a copy of your data in a structured, machine-readable format.
                    </li>
                </ul>
                <p>To exercise these rights, contact us at [Insert Contact Email].</p>

                <h2>7. Cookies and Tracking Technologies</h2>
                <p>Our dashboard uses cookies and similar technologies to enhance user experience. You can manage cookie
                    preferences through your browser settings.</p>

                <h2>8. Third-Party Links</h2>
                <p>Our dashboard may contain links to third-party websites or services. We are not responsible for the privacy
                    practices of these external sites. Please review their privacy policies separately.</p>

                <h2>9. Updates to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated
                    effective date.</p>

                <h2>10. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li><strong>Email:</strong> serik.shaikamalov@gmail.com</li>
                    <li><strong>Phone:</strong> +77772001991</li>
                    <li><strong>Address:</strong> Shugula 340/4, APT 48, Almaty state, Kazakhstan</li>
                </ul>
            </div>
        </div>
    )
}
