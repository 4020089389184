import { Outlet } from "react-router-dom";
import { UserList } from "./UserList";
import UserForm from "./UserForm";

export const usersRoutes = [
  {
    path: "users",
    element: Outlet,
    children: [
      {
        path: "",
        element: UserList,
      },
      {
        path: "add",
        element: UserForm,
      },
      {
        path: "edit/:id",
        element: UserForm,
      },
    ],
  },
];
