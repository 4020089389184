import appAxios from "services/axios";
import { BaseService } from "../_baseService";


class LogsService extends BaseService<any> {
  constructor() {
    super(`logs`);
  }

  getUserLogs = async (type: string, customerID: string) => {
    return appAxios.get(`${this.pathWithOrg()}/${type}?memberID=${customerID}`);
  };
}

export default new LogsService();
