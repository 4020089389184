import { CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { PAYMENT_METHOD } from "data/paymentConts";
import { genderEnum } from "enums/genders";
import React, { useEffect, useState } from "react";
import customersService from "services/admin/customersService";

const CustomerGenders = ({ month }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (data) {      
      const ctx = document.getElementById("customerGenders");

      new window.Chart(ctx, {
        type: "doughnut",
        data: {
          labels: data.filter(d => d).map(t => `${genderEnum[t.gender]}: ${t.total}`),
          datasets: [
            {
              label: "Распределение клиентов по полу",
              data: data.filter(d => d).map(t => t.total),
              borderWidth: 1,
              backgroundColor: ['#FF6384', '#36A2EB']
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [data]);


  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await customersService.getCustomersGenders({ month })
      const stats = result.data.filter(i => i?.gender)
      setData(stats)
    } catch (ex) {
      console.error(`CustomerGenders ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div style={{ width: 300 }}>
      <h4 className="text-center">Распределение клиентов по полу</h4>
      <p className="color-gray fs-12 m-t-8">Узнайте, кто чаще обращается за услугами (мужчины или женщины), чтобы лучше понимать свою аудиторию и учитывать её потребности.</p>
      <canvas id="customerGenders" style={{ width: '300px' }}></canvas>
    </div>
  );
};

export default CustomerGenders