import { Navigate, Route, Routes } from "react-router-dom";
import { adminRoutes } from "pages/Admin/admin-routes";
import { authRoutes } from "pages/Auth/auth-routes";
import AppLayout from "pages/AppLayout";
import { superAdminRoutes } from "pages/_SuperAdmin/superadmin-routes";
import { PrivacyPolicy } from "pages/PrivacyPolicy";

const buildRoute = (route: any, ...rest: any[]) => (
  <Route
    key={route.path}
    path={route.path}
    element={
      route.redirect ? <Navigate to={route.redirect} /> : <route.element />
    }
    {...rest}
  >
    {route?.children && route?.children?.map(buildRoute)}
  </Route>
);

const publicRoutes = [
  {
    path: "",
    element: AppLayout,
    children: [
      {
        path: "",
        element: AppLayout,
        redirect: "/auth/login",
      },
      ...authRoutes,   
      {
        path: 'privacy-policy',
        element: PrivacyPolicy
      }      
    ],
  },
];
export const routes = [...publicRoutes, ...adminRoutes, ...superAdminRoutes];

const AppRouter = () => {
  return <Routes>{routes.map(buildRoute)}</Routes>;
};

export default AppRouter;
