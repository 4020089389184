import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import { Link } from 'react-router-dom';
import usersSAService from 'services/superadmin/usersSAService';

const listConfig = [
    {
        key: "id",
        label: "ID"
    },
    {
        key: "name",
        label: "ФИО",
    },
    {
        key: "email",
        label: "Почта",
    },
    {
        key: "phone",
        label: "Телефон",
    },
    {
        key: "phonePrefix",
        label: "Код страны",
    },
    TableColumns.createdDate
]

export const UserList = () => {
    const [data, loadData, loading] = useRemoteResource(usersSAService.getAll, "");

    const onDelete = async (u) => {
        await usersSAService.delete(u.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список пользователей">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
