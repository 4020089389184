import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import { snack } from "components/Snack/Snack";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { citiesService } from "services/cities.service";
import { countriesService } from "services/countries.service";
import airportsSAService from "services/superadmin/airportsSAService";
import _ from "lodash";

/**
 * - Подумать как сделать страну и город в отдельный компонент
 */
const AirportForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { airport, countries, cities },
}) => {

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted },
    watch,
    unregister
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: _.get(airport, 'name', ''),
      airportCode: _.get(airport, 'airportCode', ''),
      countryID: _.get(airport, 'countryID', ''),
      cityID: _.get(airport, 'cityID', ''),
      timezone: _.get(airport, 'timezone', '')
    },
  });

  const onSubmit = async (countryInput) => {
    try {
      if (airport) {
        await airportsSAService.put(id, countryInput)
        snack("Аэропорт обновлена успешно!");
      } else {
        await airportsSAService.post(countryInput)
        snack("Аэропорт создана успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={airport ? 'Редактирование аэропорта' : 'Создание аэропорта'}>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth {...register('name', { required: true })}
          type='text'
          label='Название аэпопорта *'
        ></TextField>
        <TextField
          fullWidth {...register('airportCode', { required: true })}
          type='text'
          label='Код аэпопорта *'
        ></TextField>
        <TextField
          fullWidth {...register('timezone', { required: true })}
          type='text'
          label='Часовой пояс(TimeZone)'
        ></TextField>
        <Controller control={control} name="countryID" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Выберите страну</InputLabel>
            <Select label="Выберите страну" {...field} value={field.value || ''}>
              {countries ?
                countries.data.map(t => {
                  return <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                })
                : []}
            </Select>
          </FormControl>
        }}></Controller>
        <Controller control={control} name="cityID" rules={{ required: true }} render={({ field }) => {
          return <FormControl fullWidth>
            <InputLabel>Выберите город</InputLabel>
            <Select label="Выберите город" {...field} value={field.value || ''}>
              {cities ?
                cities.data.filter(c => c.countryID === getValues('countryID')).map(c => {
                  return <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                })
                : []}
            </Select>
          </FormControl>
        }}></Controller>
        <Button variant="contained" type="submit" disabled={!isValid}>
          {airport ? <span>Обновить аэропорт</span> : <span>Создать аэропорт</span>}
        </Button>

        {/* <pre className="m-t-32">
          {JSON.stringify(watch(), null, 4)}
        </pre> */}
      </form>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(AirportForm, (router) => {
    let requests = {
      cities: () => citiesService.getAll(),
      countries: () => countriesService.getAll()
    }
    if (router.params.id) {
      requests['airport'] = () => airportsSAService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
