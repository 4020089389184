import { Button } from '@mui/material'
import { PhoneInput } from 'components/PhoneInput/PhoneInput';
import { snack } from 'components/Snack/Snack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import whatsappService from 'services/admin/whatsappService';

export const WhatsappSignin = () => {
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: { errors, dirtyFields, isDirty, isValid, isSubmitting, isSubmitted, touchedFields, },
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            phone: '',
            phonePrefix: 7
        }
    });

    async function onSubmit() {
        try {
            const phone = getValues('phone')

            if (phone) {
                const { data } = await whatsappService.singin(phone)
                if (data.message) {
                    snack(data.message)
                    setTimeout(() => {
                        navigate(`/auth/whatsapp/verify?phone=${phone}`)
                    }, 500);
                }
            }
        } catch (ex) {
            console.error('WhatsappSignin ex: ', ex);
            snack('Произошла ошибка, попробуйте позже!', ex?.message)
        }
    }

    return (
        <div className='container m-t-32'>
            <div className='login-form-wrapper'>
                <div className='m-b-64'>
                    <h3>Введите ваш номер телефона Whatsapp</h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='phone-number flex flex-col gap-4 w-100'>
                        <Controller
                            control={control}
                            name="phone"
                            rules={{ required: true, minLength: 11, maxLength: 11 }}
                            render={({ field, fieldState, formState }) => {
                                return <PhoneInput
                                    phoneNumber={field.value}
                                    countryCode={getValues('phonePrefix')}
                                    hideLabel={true}
                                    onChange={(params) => {
                                        console.log(`params:`, params);

                                        if (params.countryCode) {
                                            setValue('phonePrefix', params.countryCode)
                                        }
                                        if (params.phone !== undefined) {
                                            setValue('phone', params.phone, {
                                                shouldValidate: true
                                            })
                                        }
                                    }}
                                />
                            }}
                        ></Controller>
                    </div>
                    <div className='w-100 m-t-32'>
                        <Button variant='contained' className='w-100' type='submit' disabled={!isValid || isSubmitted}>Отправить код</Button>
                    </div>
                    {/* <pre>
                        {JSON.stringify(watch(), null, 4)}
                    </pre>
                    <pre>
                        {JSON.stringify({ isValid })}
                    </pre>
                    <pre>
                        {JSON.stringify(errors, null, 4)}
                    </pre> */}
                </form>
            </div>
        </div>
    )
}
