import { Alert, Button, Card, CardContent, CardHeader, Dialog, TextField } from '@mui/material';
import { BookingStatus } from 'components/BookingStatus';
import { PaymentDialog } from 'components/PaymentDialog/PaymentDialog';
import { snack } from 'components/Snack/Snack';
import TourPackageCard from 'components/TourPackageCard/TourPackageCard';
import { diffInDays, toDate, toFullDateName } from 'helpers/dateHelper';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import bookingsService from 'services/admin/bookingsService';
import './booking.scss'
import { PaymentCard } from 'components/PaymentCard';
import paymentsService from 'services/admin/paymentsService';
import _ from 'lodash';
import { genderEnum } from 'enums/genders';
import { BookingStatusEnum } from 'data/bookingStatus';
import { OPERATION_TYPE } from 'enums/payments';

const BookingDetails = ({
    router: {
        navigate,
        params: { id },
    },
    data: {
        booking,
        tourPackages,
    },
}) => {
    // Платеж который будет передаваться к dialog
    const [payment, setPayment] = useState(null)
    const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false)

    const onSubmit = async (formValues) => {
        try {
            console.log(`formValues:`, formValues);
            if (formValues.id) {
                await paymentsService.put(formValues.id, { bookingID: id })
            } else {
                await paymentsService.post(formValues, { bookingID: id })

                snack('Платеж успешно добавлен!')

                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error(ex)
            snack(`При добавление платежа вышли ошибка: ${ex?.message}`)
        } finally {
            setIsPaymentDialogVisible(false)
        }
    }

    const completeOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.completeOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время отмены заказа вышла ошибка: ${ex?.message}`)
        }
    }

    const cancelOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.cancelOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время отмены заказа вышла ошибка: ${ex?.message}`)
        }
    }
    const refundOrder = async () => {
        try {
            if (booking) {
                const { data } = await bookingsService.refundOrder(booking.id)
                snack(data.message)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
        } catch (ex) {
            console.error('cancelOrder ex:', ex)
            snack(`Во время возврата заказа вышла ошибка: ${ex?.message}`)
        }
    }

    function bookingButtons() {
        /**
         * Завершить заказ - отображать когда заказ СОЗДАН или ОПЛАЧЕНО ЧАСТИЧНО
         * Оформить возврат - отображать когда денежные средства получены: то есть ОПЛАЧЕНО ЧАСТИЧНО и оплачено полностью
        */
        if ([BookingStatusEnum.CREATED].includes(booking.bookingStatusID)) {
            return <div className='flex gap-16'>
                <Button type="button" variant="outlined" onClick={cancelOrder} color='error'>Отменить заказ</Button>
            </div>
        }
        if ([BookingStatusEnum.PARTIAL_PAYMENT].includes(booking.bookingStatusID)) {
            // Если заказ оплачен частично то отображаем завершить заказ и оформить возврат.
            return <div className='flex gap-16'>
                <Button type="button" variant="contained" onClick={completeOrder} color='success'>Завершить заказ</Button>
                {/* <Button type="button" variant="outlined" onClick={refundOrder} color='error'>Оформить возврат</Button> */}
            </div>
        }
        // if ([BookingStatusEnum.COMPLETED].includes(booking.bookingStatusID)) {
        //     //  Все равно разрешаем делать возврат после получения полной суммы 
        //     return <div className='flex gap-16'>
        //         <Button type="button" variant="outlined" onClick={refundOrder} color='error'>Оформить возврат</Button>
        //     </div>
        // }

        if ([BookingStatusEnum.REFUND].includes(booking.bookingStatusID)) {
            // Если заказ статус возврат то ничего не отображаем
            return null
        }

        return null
    }

    const showPaymentDialog = (operationType) => {
        console.log(`operationType: `, operationType);
        setPayment({
            operationType: OPERATION_TYPE[operationType]
        })
        setIsPaymentDialogVisible(true)
    }

    return (
        <div className='m-b-64'>
            <div>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Данные о заказе">
                        Данные о заказе
                    </CardHeader>
                    <CardContent>
                        <div className='profile'>
                            <div className="m-b-16">
                                <strong>Номер заказа:</strong> #{booking.id}
                            </div>
                            <div className="m-b-16">
                                <strong>Дата создания заказа: </strong>
                                <span>{booking.createdAt ? toDate(booking.createdAt) : 'Отсутсвует'}</span>
                            </div>
                            <div className="m-b-16">
                                <strong>Дата последнего изменения: </strong>
                                <span>{booking.updatedAt ? toDate(booking.updatedAt) : 'Отсутсвует'}</span>
                            </div>
                            <div className="m-b-16">
                                <strong>Прикрепленный менеджер: </strong>
                                <span>{booking.manager?.name ? <Link className="color-blue" target="_blank" to={`/admin/members/view/${booking.manager.id}`}>{booking.manager?.name}</Link> : 'Отсутсвует'}</span>
                            </div>
                            <div className="m-b-16">
                                <strong>Статус заказа: </strong>
                                <BookingStatus bookingStatus={booking.bookingStatus}></BookingStatus>
                            </div>
                            <div className="m-b-16">
                                <strong>Общая сумма к оплате: </strong>
                                <span>{booking.totalPrice}$</span>
                            </div>
                            <div className="m-t-16 gap-2 flex">
                                <strong>Оплатить до:</strong>
                                <span>{toFullDateName(booking.tourPackage.startDate)}</span>
                                <strong className='color-green'>(осталось {diffInDays(booking.tourPackage?.startDate, new Date())} дней)</strong>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Данные о покупателе" subheader="Покупатель не обязательно сам турист"></CardHeader>
                    <CardContent>
                        {booking.buyer ? <>
                            <p><Link className='color-blue' target='_blank' to={`/admin/customers/view/${booking.buyer.id}`}>{booking.buyer.name}</Link></p>
                            <p>Номер телефона: <span>+{_.get(booking, 'buyer.user.phone')}</span></p>
                            <p>ИИН: <span>{_.get(booking, 'buyer.IDCard.iin')}</span></p>
                            <p>Является клиентом: {toFullDateName(booking.createdAt)}</p>
                        </> : null}
                    </CardContent>
                </Card>
                <Card className='p-16 m-b-32'>
                    <CardHeader title="Выбранный турпакет"></CardHeader>
                    <CardContent>
                        <TourPackageCard tourPackage={booking.tourPackage} options={{ cssClass: 'card-reset', hideButtons: true }}></TourPackageCard>
                    </CardContent>
                </Card>
                <h2 className='m-t-32 m-b-16'>Список туристов:</h2>
                {booking.customers.map((c, inx) => {
                    return <Card className='p-16 m-b-32' key={inx}>
                        <CardHeader subheader={`${inx + 1}-турист`}></CardHeader>
                        <CardContent>
                            <div className='m-b-16 flex gap-16 flex-between'>
                                <section>
                                    <div key={c.id} className='m-b-8'>
                                        <Link className='color-blue' to={`/admin/customers/view/${c.id}`}>
                                            <strong>{c.name || c.ID}</strong>
                                        </Link>
                                    </div>
                                    <div className='m-b-8'>
                                        <span>Дата рождения: </span><span>{_.get(c, 'birthdate')}</span>
                                    </div>
                                    <div className='m-b-8'>
                                        <span>Телефон: </span><span>{_.get(c, 'user.phone')}</span>
                                    </div>
                                    <div className='m-b-8'>
                                        <span>Пол: </span><span>{_.has(c, 'gender') ? genderEnum[_.get(c, 'gender')] : null}</span>
                                    </div>
                                    <div className='m-b-8'>
                                        <span>Гражданство: </span><span>{_.get(c, 'citizenship')}</span>
                                    </div>
                                    <div className='m-t-8'>Является клиентом с {toFullDateName(_.get(c, 'createdAt'))}</div>
                                </section>
                                <section>
                                    <h4 className='m-b-16'>Удостоверение личности</h4>
                                    <div className='m-b-8 flex gap-8'>
                                        <div>
                                            <span>ИИН: </span><span>{_.get(c, 'IDCard.iin')}</span>
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <h4 className='m-b-16'>Паспорт</h4>
                                    <div className='m-b-8'>
                                        {_.has(c, 'passport.dateOfIssue') ?
                                            <div>
                                                <span>Дата выдачи: </span><span>{_.get(c, 'passport.dateOfIssue')}</span>
                                            </div> : null
                                        }
                                        <div>
                                            <span>Годен до: </span><span>{_.get(c, 'passport.dateOfExpire')}</span>
                                        </div>
                                        <div>
                                            <span>Номер: </span><span>{_.get(c, 'passport.number')}</span>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div>
                                Сумма к оплате: {_.get(c, 'CustomerToBooking.amount')} {_.get(c, 'CustomerToBooking.currency')}
                            </div>
                        </CardContent>
                    </Card>
                })}
                <PaymentCard booking={booking} onClose={showPaymentDialog}></PaymentCard>

                <Alert className='m-t-32 m-b-32' color='info'>
                    <div className='m-b-16'><strong>Обратите внимание: </strong>Заказы могут быть находиться в следующих статусах:</div>
                    <ul className=''>
                        <li>
                            <strong>Новый</strong>. Заказ только что создан, ожидает обработки менеджером.
                        </li>
                        <li>
                            <strong>Ожидание оплаты</strong>: Система ожидает, когда клиент произведет оплату. Это может происходить через различные методы оплаты, такие как кредитные карты, электронные кошельки, банковские переводы и т.д.
                        </li>
                        <li>
                            <strong>"Завершен"</strong>. Заказ считается завершенным только после того, как вся сумма была оплачена (либо полная оплата, либо все предоплаты были получены и покрывают общую сумму). Заказ больше не требует дальнейших действий со стороны бизнеса или клиента, и все обязательства выполнены.</li>
                        <li>
                            <strong>Отменен.</strong> Заказ отменен по какой-либо причине. Например: клиент отказался от поездки или менеджер создал неправильный заказ                     </li>
                        <li>
                            <strong>Возврат</strong>. Оформляется возврат по заказу (полностью или частично).
                        </li>
                        <li>Примечание: После завершения, оформление возврата или отмены заказа вы больше не можете редактировать заказ!</li>
                    </ul>
                </Alert>
                {bookingButtons()}
            </div>
            <Dialog open={isPaymentDialogVisible} onClose={() => { setIsPaymentDialogVisible(false) }} className='p-16 w-100' fullWidth maxWidth="sm">
                <PaymentDialog onSubmit={onSubmit} payment={payment}></PaymentDialog>
            </Dialog>
        </div>
    )
}

export default withRouter(
    withRemoteDataAndSpinner(BookingDetails, (router) => {
        let requests = {}
        if (router.params.id) {
            requests['booking'] = () => bookingsService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);