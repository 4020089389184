import { Button, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material'
import { getDaysFromStartDate, getWeekDay, toDate, weekDaysAsString, weekDaysToNumber } from 'helpers/dateHelper'
import moment from 'moment'
import { useRef, useState } from 'react'

export const FlightsDialog = ({ onSelect, startDate, schedules }) => {
    const [flights, setFlights] = useState([])
    const selectedScheduleRef = useRef()

    function generateFlights(e) {
        const scheduleID = selectedScheduleRef.current.value
        console.log(`selectedScheduleRef: `, scheduleID);
        const schedule = schedules.find(s => s.id === scheduleID)

        // Generate 3 flights based on Schedule
        if (schedule.scheduleDays) {
            let _flights = []
            const days = getDaysFromStartDate(startDate)
            const weekDaysInNumberFormat = weekDaysToNumber(schedule.scheduleDays)

            _flights = days.filter(d => {
                console.log(`Weekday:`, moment(d).format('dddd').toLowerCase());
                const weekDay = moment(d).isoWeekday()
                return weekDaysInNumberFormat.includes(weekDay)
            }).map(d => {
                const weekDay = moment(d).isoWeekday()
                const departureDate = moment(d).add(schedule.departureTime)
                let arrivalDate = moment(departureDate).add(schedule.durationHours, 'hours').add(schedule.durationMinutes, 'minutes')

                const departureAirportTimezone = Number(schedule.arrivalAirport.timezone) - Number(schedule.departureAirport.timezone)

                return {
                    TourToFlight: {
                        departureDate: departureDate.format('YYYY-MM-DD HH:mm'),
                        departureWeekDay: weekDay,
                        arrivalDate: arrivalDate.add(departureAirportTimezone, 'hours').format('YYYY-MM-DD HH:mm'),
                    },
                    ...schedule,
                }
            })
            setFlights([..._flights])
            console.log(`_flights: `, _flights);
        }
    }

    return (
        <div>
            <DialogTitle>Рейсы</DialogTitle>
            <DialogContent>
                <div className='flex'>
                    <Select style={{ width: 300 }} inputRef={selectedScheduleRef}>
                        {schedules.map(s => {
                            return <MenuItem value={s.id}>{s.flightNumber}: {s.departureAirport.airportCode}-{s.arrivalAirport.airportCode}</MenuItem>
                        })}
                    </Select>
                    <Button onClick={() => generateFlights()}>Найти рейсы</Button>
                </div>
                <div className='m-t-32'>
                    <div className='m-b-16' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                        <div>Номер рейса</div>
                        <div>Направление</div>
                        <div>Дата вылета</div>
                        <div>День вылета</div>
                        <div></div>
                    </div>
                    {flights.map((f, inx) => {
                        return <div key={inx} className='m-b-8 gap-2' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                            <div>{f.flightNumber}</div>
                            <div>{f.departureAirport.airportCode}-{f.arrivalAirport.airportCode}</div>
                            <div>{toDate(f.TourToFlight.departureDate)}</div>
                            <div>{getWeekDay(f.TourToFlight.departureDate)}</div>
                            <Button onClick={() => onSelect(f)} type='button'>Выбрать</Button>
                        </div>
                    })}
                </div>
            </DialogContent>
        </div>
    )
}
