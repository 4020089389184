import { toQueryParams } from "helpers/url";
import appAxios from "./axios";
import { LIMIT } from "data/consts";

export class BaseService<T> {
  constructor(public basePath: string) { }

  pathWithOrg() {
    const orgID = localStorage.getItem("orgID");
    const path = ["/admin"];
    if (orgID) {
      path.push("orgs", orgID);
    }
    path.push(this.basePath);
    const pathString = path.join("/");
    return pathString;
  }

  getAll = (queryParams: object = {}): Promise<T[]> => {
    queryParams = {
      ...queryParams,
      limit: LIMIT
    }
    return appAxios.get(this.pathWithOrg() + "?" + toQueryParams(queryParams));
  };
  get = (id: number): Promise<T> => appAxios.get(`${this.pathWithOrg()}/${id}`);
  delete = (id: number): Promise<number> => appAxios.delete(`${this.pathWithOrg()}/${id}`);
  post = (data: number, queryParams = {}): Promise<T> => appAxios.post(`${this.pathWithOrg()}` + "?" + toQueryParams(queryParams), data);
  put = (id: number, data: T): Promise<T> => appAxios.put(`${this.pathWithOrg()}/${id}`, data);
  patch = (id: number, data: T): Promise<T> => appAxios.patch(`${this.pathWithOrg()}/${id}`, data);
}
