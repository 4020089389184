import ListPage from "components/ListPage/ListPage";
import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import membersService from "services/admin/membersService";
import { CircularProgress } from "@mui/material";
import MemberCard from "components/MemberCard";

const MemberList = () => {
  const [data, loadData, loading] = useRemoteResource(membersService.getAll, "");

  const onDelete = async (item) => {
    await membersService.delete(item.id);
    loadData();
  };

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  return (
    <ListPage pageTitle="Список сотрудников">
      {data?.data?.map(m => {
        return <div className="m-b-16"><MemberCard member={m}></MemberCard></div>
      })}
    </ListPage>
  );
};

export default withRouter(MemberList);
